import React from "react"
import { Row, Col } from "react-bootstrap"

import "./socialRow.css"

export default () => (
  <Row className="jumbo_social text-center">
      <Col>
    </Col>
    <Col>
    </Col>
    <Col>
    </Col>
    <Col>
      <a
        style={{ color: "blueviolet" }}
        href="https://www.github.com/mdfla"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Maurice's Github"
      >
        <i className="fab fa-github fa-2x hover_effect" />
      </a>
    </Col>
    <Col>
      <a
        style={{ color: "#0077B5" }}
        href="https://www.linkedin.com/mauriceflack"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Maurice's linked-In"
      >
        <i className="fab fa-linkedin fa-2x hover_effect" />
      </a>
    </Col>
    <Col>
      <a
        style={{ color: "#38A1F3" }}
        href="https://twitter.com/mauriceflack"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Maurice's twitter"
      >
        <i className="fab fa-twitter fa-2x hover_effect" />
      </a>
    </Col>
  </Row>
)
