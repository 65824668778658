import React from "react"

import { Container, Jumbotron, Row, Col } from "react-bootstrap"

import "./footer.css"
import "../../fontawesome/css/all.min.css"
import SocialRow from "../SocialRow/SocialRow"

const Footer = () => (
  <Jumbotron fluid style={footer_styles}>
    <Container style={{ maxWidth: "960px" }}>
      <Row>
        <Col>
          <h3>Maurice Flack</h3>
          <span>Email: </span>
          <a href="mailto:maurice.flack@hotmail.com">
            <span style={{ color: "blue" }}>maurice.flack@hotmail.com</span>
          </a>
          <br />
          <p>
            Phone Number: 
            (210) 792-0032
          </p>
        </Col>
        <SocialRow />
      </Row>

      <Row className="text-center" style={{ marginTop: "3rem" }}>
        <Col>
          Maurice Flack © {new Date().getFullYear()}, Built with
          <i
            className="fas fa-mug-hot"
            style={{
              marginLeft: "10px",
              marginRight: "3px",
              fontSize: "1.5rem",
            }}
          />
          <a href="https://www.gatsbyjs.org">
            {" "}
            <span className="menu_link">& Gatsby</span>
          </a>
        </Col>
      </Row>
    </Container>
  </Jumbotron>
)

const footer_styles = {
  marginTop: "3rem",
  marginBottom: "0",
  backgroundColor: "#000",
  color: "#fff",
  paddingBottom: "0",
  width: "100%",
}

export default Footer
