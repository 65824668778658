import React from "react"
import { Link } from "gatsby"
import { Navbar } from "react-bootstrap"
import { Nav } from "react-bootstrap"

import "./myNavbar.css"


const Navbar2 = () => (
    <Navbar sticky="top" bg="dark" variant="dark" className="shadow p-3 mb-5 bg-dark rounded">
        <Navbar.Brand><Link to="/" className="menu_link float_left">Maurice Flack</Link></Navbar.Brand>
        <Nav className="ml-auto">
            <Link to="/" className="menu_link float_right">Home</Link>
            <Link to="/about" className="menu_link float_right">About</Link>
            <Link to="/projects" className="menu_link float_right">Projects</Link>
        </Nav>
    </Navbar>
)

export default Navbar2