import React from "react"
import Layout from "../components/layout/Layout"
import MyJumbo from "../components/myJumbo/MyJumbo"
import MyButton from "../components/myButton/MyButton"

import "../fontawesome/css/all.min.css"
import "./index.css"

import { Container, Row, Col } from "react-bootstrap"

import CV from "/" //Import you CV file here!
import other from "/" //Import other downloadable here

export default () => (
  <div className="App">
    <Layout>
      <MyJumbo
        body={
          "So you are looking for a professional, communicative & punctual software engineer who likes to keep productive."
        }
        title={"About me"}
      />
      <hr />
      <Container fluid>
        <Row style={{ marginTop: "2rem", marginBottom: "1.5rem" }}>
          <MyButton text={"CV download"} URL={CV} />
          <MyButton text={"Other Downloadable"} URL={other} />
        </Row>
        <Row>
          <Col>
            <h3>Summary</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>I love creating things and solving problems</h5>
            <br />
            <p>
              I'm a Front-end Developer building Websites and Applications on the internet.
              <br />
              <br />
              The current Front-end skills I am familiar with is: JavaScript, Html5, CSS3, React.js, and Gatsby.
              <br />
              <br /> 
              Although I am not too familiar with the backend, I have at least explored: Node.js, Express.js, Redux, and PostgreSQL.
            </p>
          </Col>
        </Row>
        <hr />
        {/* This is the beginning of the career section*/}
        <Row>
          <Col>
            <h3>Past work experience</h3>
          </Col>
        </Row>
        {/* Position number 1*/}
        <Row>
          <Col>
            <h5>Credit and Customer Service Representative</h5>
          </Col>
        </Row>
        <Row>
          <Col>Kohl's, San Antonio, Texas</Col>
          <Col>2018/08 - Present</Col>
        </Row>
        <Row>
          <Col>
            <p style={{ marginTop: "1rem" }}>
              <li>Assisting with hundreds customer’s online shopping orders including any sales, missing and damaged items, password resets, gift cards, and information inquiries.</li>
              <li>Aiding with Kohl’s Charge Card inquiries, informational changes, payments, password resets and over the phone case submission requests.</li>
            </p>
          </Col>
        </Row>
        <hr />
        {/* SW intern Position*/}
        <Row>
          <Col>
            <h5>Overnight Stocker</h5>
          </Col>
        </Row>
        <Row>
          <Col>HEB, San Antonio, Texas</Col>
          <Col>2017/10 - 2018/07</Col>
        </Row>
        <Row>
          <Col>
            <p style={{ marginTop: "1rem" }}>
            <li>Separated several thousand cases of merchandise each week into necessary aisles for stocking before and during store opening.</li>
            <li>Prevented loss by back stocking items and cleaning aisles to be displayed.</li>
            </p>
          </Col>
        </Row>
        <hr />
        {/* This is the beginning of the education section*/}
        <Row>
          <Col>
            <h3>Education</h3>
          </Col>
        </Row>
        {/* Masters Degreee*/}
        <Row>
          <Col>
            <h5>High School Diploma</h5>
          </Col>
        </Row>
        <Row>
          <Col>Ridge View High School, Columbia, South Carolina</Col>
          <Col>2006/08 - 2009/06</Col>
        </Row>
        <Row>
          <Col>
            <p style={{ marginTop: "1rem" }}>
              <strong>Specialization:</strong>
              <br /> During my time in High School, I dabbled in a little bit of web development, creating basic static webpages. The career path at that time was
              more towards Computer-Aided Drafting and Design using the AutoCAD suite. After a while of using the suite I did not find interest in the software and thus progressed
              towards a role in programming. Out of the few technologies that I found interest I choose between Python and JavaScript and stuck with JavaScript because it affected the 
              internet.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </div>
)
