import React from "react"

import { Container, Row, Col } from "react-bootstrap"

import "../../fontawesome/css/all.min.css"

import "./summary.css"

import Gatsby from '../../favicon.png'



const Summary = () => (
  <Container className="content" fluid>
    <h3>Current tools I use:</h3>
    <br />
    <Row className="text-center tech_icons">
      <Col>
        <i className="fab fa-js" />
        <br />
        <span>JavaScript</span>
      </Col>
      <Col>
        <i className="fab fa-react" />
        <br />
        <span>React.js</span>
      </Col>
      <Col>
        <i className="fab fa-html5" />
        <br />
        <span>HTML5</span>
      </Col>
      <Col>
        <i className="fab fa-css3" />
        <br />
        <span>CSS3</span>
      </Col>
      <Col>
        <i className="fab fa-bootstrap" />
        <br />
        <span>Bootstrap</span>
      </Col>
      <Col>
        <img src={Gatsby} alt="Gatsby's Icon" width="80px" height="80px" />
        <br />
        <span>Gatsby</span>
      </Col>
    </Row>
    <br />

    <h4 className="goal_text text-center">
      My biggest goal as an Front-end Developer is to never stop learning, and work on cool
      stuff. Here's a couple of things I'm good at:
    </h4>
    <Row style={{ marginTop: "1.8rem" }}>
      <Col>
        <h4 >Problem Solving</h4>
        <p>
          Math and problem solving are the things I enjoy the most. There are a ton of problems and 
          a lot of numbers, starting a project involving data and a front-end, makes up a bunch
          of free time. Since I'm learning new frameworks and technology, using what I've learned to 
          create a new tool or solve an everyday issue is exciting to me.
        </p>
      </Col>
      <Col>
        <h4>Creativity</h4>
        <p>
          Being creative and solving a problem goes together like peanut butter and jelly.
          Although sometimes doesn't require to be creative and just needs a simple solution,
          but like all problems, there are more than 1 ways to solve a problem. Being about to
          find a new way to solve problem while learning about new technologies is what I look
          forward to everytime I start a new project.
        </p>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <h4>Communication</h4>
        <p>
          Being in the customer service role for more than 10 years, I can easily
          say the most important part of resolving any issue is communication. It 
          goes a long way to not only explain the process in a way the person can understand
          but also to address the emotional needs, to make sure that all issues are addressed.
          My communication is key to a better teamwork, better results, and finding better solutions.
        </p>
      </Col>
      <Col>
        <h4>Teamwork</h4>
        <p>
          Anyone can solve a problem with a given amount of time. However working 
          together as a team, resolves the problem much more effienctly and accurately.
          Every job requires some sort of teamwork, and as a team player and sometimes a leader
          I am willing to make sure that not only is the job done, but to make sure we get the job
          done together.
        </p>
      </Col>
    </Row>
    <br />
    <h4 className="goal_text text-center">
      These 4 skills are what lets me an Awesome Teammate eager and willing to learn anything interesting.
    </h4>
  </Container>
)

export default Summary
